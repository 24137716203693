import React, { useEffect, useRef, useState } from "react";
import closeIcon from "../../../assets/images/quix-close.png";
import { connect, useDispatch } from "react-redux";
import { API_URL, localStoragePrefix } from "../../../../utils/config";
import {
  errorMessageModal,
  successMessageModal,
} from "../../../components/Modal/MessagesModal";
import {
  getAllPricingPlansListAdmin,
  updatePricingPlanAdmin,
} from "../../../../redux/actions/admin/pricingPlans";
import dummyImg from "../../../assets/images/dummy-image.jpg";
import { checkServerURL } from "../../../../helper/helper";
import { adminEditPricingPlanSachema } from "./Validations";

const EditPricingPlan = ({ isShowModal, closeModal, pricingData }) => {
  const cardRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        closeModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeModal]);

  const userID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    price: "",
    price_string: "",
    duration: "",
    description: "",
    features: "",
    status: "1",
  });

  // Untititled
  const [unlimited, setUnlimited] = useState({
    status: false,
    screenshots: -1,
    videos: -1,
    message: "",
  });
  // Watermark
  const [watermark, setWatermark] = useState({
    status: false,
    message: "",
  });
  // UrlSummary
  const [urlSummary, setUrlSummary] = useState({
    status: false,
    message: "",
  });

  // UnlimitedLength
  const [unlimitedLength, setUnlimitedLength] = useState({
    status: false,
    length: -1,
    message: "",
  });

  // Gradient
  const [gradient, setGradient] = useState({
    status: false,
    message: "",
  });

  // Emoji
  const [emoji, setEmoji] = useState({
    status: false,
    message: "",
  });

  // Crop pip
  const [cropPip, setCropPip] = useState({
    status: false,
    message: "",
  });

  // Media Crop Option
  const [mediaCropOption, setMediaCropOption] = useState({
    status: false,
    message: "",
  });

  // Call out
  const [callOut, setCallOut] = useState({
    status: false,
    message: "",
  });

  // Gif
  const [gif, setGif] = useState({
    status: false,
    message: "",
  });

  // Resolution
  const [resolution, setResolution] = useState({
    status: false,
    resolutions: {
      "360p": true,
      "480p": true,
      "720p": true,
      "1080p": false,
      "4K": false,
    },
    message: "",
  });

  // Recovery
  const [recovery, setRecovery] = useState({
    status: false,
    message: "",
  });

  // UserManagement
  const [userManagement, setUserManagement] = useState({
    status: false,
    message: "",
  });

  // Integrations
  const [integrations, setIntegrations] = useState({
    status: false,
    integration_media: {
      facebook: false,
      linkedin: false,
      twitter: false,
      whats_app: false,
      telegram: false,
      evernote: false,
      dropbox: false,
      notion: false,
      slack: false,
      drive: false,
      youtube: false,
      team: false,
      share_point: false,
      onenote: false,
      email: false,
      qr_code: false,
      third_party: false,
    },
    message: "",
  });

  // Speech to Text
  const [speechToText, setSpeechToText] = useState({
    status: false,
    message: "",
  });

  // Text to Speech
  const [textToSpeech, setTextToSpeech] = useState({
    status: false,
    message: "",
  });


  // Download
  const [download, setDownload] = useState({
    status: false,
    formats: {
      videos: {
        webm: true,
        mp4: false,
      },
      screenshots: {
        png: true,
        jpg: false,
        pdf: false,
      },
    },
    message: "",
  });

  const initialState = {
    alt: "photo",
    src: pricingData?.picture
      ? checkServerURL(API_URL, pricingData?.picture)
      : dummyImg,
  };

  const [{ alt, src }, setPreview] = useState(initialState);
  const [showPhoto, setPhoto] = useState(null);

  useEffect(() => {
    const parsedFeatures =
      pricingData?.features &&
      JSON.stringify(JSON.parse(pricingData?.features));

    setFormData({
      price: pricingData?.price,
      price_string: pricingData?.price_string,
      duration: pricingData?.duration,
      description: pricingData?.description,
      features: JSON.stringify(pricingData?.features),
      status: pricingData?.status,
    });
    if (parsedFeatures) {
      setUnlimited(JSON.parse(parsedFeatures)?.Unlimited);
      setWatermark(JSON.parse(parsedFeatures)?.Watermark);
      setUrlSummary(JSON.parse(parsedFeatures)?.UrlSummary);
      setUnlimitedLength(JSON.parse(parsedFeatures)?.UnlimitedLength);
      setGradient(JSON.parse(parsedFeatures)?.Gradient);
      setEmoji(JSON.parse(parsedFeatures)?.Emoji);
      setCropPip(JSON.parse(parsedFeatures)?.CropPip);
      setMediaCropOption(JSON.parse(parsedFeatures)?.MediaCropOption);
      setCallOut(JSON.parse(parsedFeatures)?.CallOut);
      setGif(JSON.parse(parsedFeatures)?.Gif);
      setResolution(JSON.parse(parsedFeatures)?.Resolution);
      setRecovery(JSON.parse(parsedFeatures)?.Recovery);
      setUserManagement(JSON.parse(parsedFeatures)?.UserManagement);
      setIntegrations(JSON.parse(parsedFeatures)?.Integrations);
      setSpeechToText(JSON.parse(parsedFeatures)?.SpeechToText);
      setTextToSpeech(JSON.parse(parsedFeatures)?.TextToSpeech);
      setDownload(JSON.parse(parsedFeatures)?.Download);
    }

    setPreview(initialState);
    setPhoto(pricingData?.picture);
  }, [pricingData]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setFormData({ ...formData, [name]: value });
  };

  const [loading, setLoading] = useState(false);
  const fileFormat = ["image/jpg", "image/jpeg", "image/png"];

  const handleUploadImage = (e) => {
    let file = e.target.files;
    let fileType = file?.[0]?.type;
    if (fileFormat.indexOf(fileType) > -1) {
      setPreview(
        file?.length
          ? {
            src: URL.createObjectURL(file?.[0]),
            alt: file?.[0]?.name,
          }
          : initialState
      );
      setPhoto(file?.[0]);
    } else {
      errorMessageModal(
        "Unsupported File Format",
        "Please upload an image in PNG, JPEG, or JPG format"
      );
    }
  };

  const [formErrors, setFormErrors] = useState("");
  const handleSubmit = () => {
    const initfeaturedObj = {
      Unlimited: unlimited,
      Watermark: watermark,
      UrlSummary: urlSummary,
      UnlimitedLength: unlimitedLength,
      Gradient: gradient,
      Emoji: emoji,
      CropPip: cropPip,
      MediaCropOption: mediaCropOption,
      CallOut: callOut,
      Resolution: resolution,
      Gif: gif,
      Recovery: recovery,
      UserManagement: userManagement,
      Integrations: integrations,
      SpeechToText: speechToText,
      TextToSpeech: textToSpeech,
      Download: download,
    };
    const payload = {
      id: String(pricingData?.id),
      admin_id: pricingData?.admin_id,
      price: formData?.price,
      status: formData?.status,
      picture: showPhoto,
      price_string: formData?.price_string?.trim(''),
      duration: formData?.duration,
      description: formData?.description,
      features: JSON.stringify(initfeaturedObj),
    };
    if (adminEditPricingPlanSachema({ formData: payload, setFormErrors })) {
      setLoading(true);
      dispatch(
        updatePricingPlanAdmin(payload, (type, res) => {
          if (type === "success") {
            setLoading(false);
            closeModal(false);
            successMessageModal(
              "Pricing Plan Successfully Updated.",
              "Pricing plan details have been updated successfully."
            );
            dispatch(getAllPricingPlansListAdmin(Number(userID)));
          } else if (type === "fail") {
            setLoading(false);
            errorMessageModal("Edit Pricing Plan.", res?.message);
            dispatch(getAllPricingPlansListAdmin(Number(userID)));
            closeModal(false);
          }
        })
      );
    }
  };

  return (
    <div className="product-plans-edit" ref={cardRef}>
      <div id="quix-dashboard-popup" className="quix-edit-pricing-plan">
        <div className="quix-dashboard-popup-inner quix-edit-pricing-plan-outer">
          <div className="quix-dashboard-popup-title text-start">
            <div className="quix-dashboard-popup-title-inner ps-4">
              <span>Edit Pricing Plan</span>
            </div>
          </div>
          <div className="quix-dashboard-popup-body">
            <div className="quix-pricing-edit-details px-4">
              <div className="quix-form-outer">
                <div className="quix-add-pricing-plan">
                  <div className="row">
                    <div className="col-md-12 edit-pricing-plan">
                      <div className="product-upload-img d-flex gap-3 align-items-center">
                        <div className="card upload-card">
                          <img src={src} height={"100%"} width={"100%"} />
                          <input
                            type="file"
                            name="upload-img"
                            className="upload-pricing-img"
                            onChange={(e) => handleUploadImage(e)}
                            accept={fileFormat.join(",")}
                          />
                        </div>
                        <div className="upload-cover mt-2">
                          <div className="d-flex gap-2">
                            <div className="upload-btn upload-card">
                              <input
                                type="file"
                                name="upload-img"
                                accept={fileFormat.join(",")}
                                onChange={(e) => handleUploadImage(e)}
                                className="upload-pricing-img"
                              />
                              <button
                                type="button"
                                className="btn upload-photo-btn"
                              >
                                Upload photo
                              </button>
                            </div>
                            <button
                              type="button"
                              className="btn remove-photo-btn"
                            >
                              Remove photo
                            </button>
                          </div>
                          <p>at least 132 x 132px PNG, JPEG or JPG file.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="price"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Price
                      </label>
                      <div className="col-sm-12 text-start">
                        <input
                          className="form-control"
                          type="text"
                          name="price"
                          value={formData?.price}
                          id="price"
                          onChange={(e) => handleChange(e)}
                          maxLength={255}
                        />
                        {formErrors?.priceError && (
                          <p className="text-danger account-dec m-0">
                            {formErrors?.priceError}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="status"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Status
                      </label>
                      <div className="col-sm-12">
                        <select
                          className="form-control"
                          name="status"
                          value={formErrors?.status}
                          id="status"
                          onChange={(e) => handleChange(e)}
                        >
                          <option value={"1"} selected={formData?.status === '1' ? true : false}>Active</option>
                          <option value={"0"} selected={formData?.status === '0' ? true : false}>Inactive</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="price-string"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Price String
                      </label>
                      <div className="col-sm-12 text-start">
                        <input
                          className="form-control"
                          type="text"
                          name="price_string"
                          id="price-string"
                          value={formData?.price_string}
                          onChange={(e) => handleChange(e)}
                          maxLength={255}
                        />
                        {formErrors?.priceStringError && (
                          <p className="text-danger account-dec m-0">
                            {formErrors?.priceStringError}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="duration"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Duration
                      </label>
                      <div className="col-sm-12 text-start">
                        <input
                          className="form-control"
                          type="text"
                          name="duration"
                          id="duration"
                          value={formData?.duration}
                          onChange={(e) => handleChange(e)}
                          maxLength={255}
                        />
                        {formErrors?.durationError && (
                          <p className="text-danger account-dec m-0">
                            {formErrors?.durationError}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="description"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Description
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="description"
                          id="description"
                          value={formData?.description}
                          onChange={(e) => handleChange(e)}
                          maxLength={255}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3 text-start">
                      <label
                        htmlFor="staticEmail"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Features
                      </label>
                      <div className="quixy-feature-row-outer">
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="unlimited"
                              onChange={(e) =>
                                setUnlimited(() => ({
                                  ...unlimited,
                                  status: e.target.checked,
                                }))
                              }
                              checked={
                                unlimited?.status === true ? true : false
                              }
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="unlimited">Unlimited</label>
                          </div>
                          {/* {unlimited?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row">
                            <div className="mb-3">
                              <label className="col-form-label">
                                Screenshots
                              </label>
                              <input
                                onChange={(e) =>
                                  setUnlimited(() => ({
                                    ...unlimited,
                                    screenshots: e?.target?.value,
                                  }))
                                }
                                type="number"
                                name="screenshots"
                                value={unlimited?.screenshots}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="col-form-label">Videos</label>
                              <input
                                onChange={(e) =>
                                  setUnlimited(() => ({
                                    ...unlimited,
                                    videos: e?.target?.value,
                                  }))
                                }
                                type="number"
                                name="videos"
                                value={unlimited?.videos}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setUnlimited(() => ({
                                    ...unlimited,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={unlimited?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="download"
                              onChange={(e) =>
                                setDownload(() => ({
                                  ...download,
                                  status: e.target.checked,
                                }))
                              }
                              checked={download?.status === true ? true : false}
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="download">Download</label>
                          </div>
                          {/* {download?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row d-block">
                            <div className="video-opt">
                              <span className="col-form-label">Video</span>
                              <div className="gap-3 d-flex">
                                {Object.keys(download.formats.videos).map(
                                  (format) => (
                                    <div
                                      key={format}
                                      className="download-opt d-flex align-items-center gap-1"
                                    >
                                      <input
                                        onChange={() => {
                                          setDownload((prevState) => ({
                                            ...prevState,
                                            formats: {
                                              ...prevState.formats,
                                              videos: {
                                                ...prevState.formats.videos,
                                                [format]:
                                                  !prevState.formats.videos[
                                                  format
                                                  ],
                                              },
                                            },
                                          }));
                                        }}
                                        id={format}
                                        type="checkbox"
                                        name={format}
                                        checked={
                                          download?.formats?.videos?.[
                                            format
                                          ] === true
                                            ? true
                                            : false
                                        }
                                        className="form-check-input"
                                      />
                                      <label
                                        htmlFor={format}
                                        className="col-form-label"
                                      >
                                        {format}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            <div className="screen-opt">
                              <span className="col-form-label">Screcnshot</span>
                              <div className="d-flex align-items-center gap-3">
                                {Object.keys(download.formats.screenshots).map(
                                  (format) => (
                                    <div
                                      key={format}
                                      className="download-opt d-flex align-items-center gap-1"
                                    >
                                      <input
                                        id={format}
                                        type="checkbox"
                                        checked={
                                          download.formats.screenshots[format]
                                        }
                                        onChange={() => {
                                          setDownload((prevState) => ({
                                            ...prevState,
                                            formats: {
                                              ...prevState.formats,
                                              screenshots: {
                                                ...prevState.formats
                                                  .screenshots,
                                                [format]:
                                                  !prevState.formats
                                                    .screenshots[format],
                                              },
                                            },
                                          }));
                                        }}
                                      />
                                      <label
                                        htmlFor={format}
                                        className="col-form-label"
                                      >
                                        {format}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setDownload(() => ({
                                    ...download,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={download?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="unlimititedlength"
                              onChange={(e) =>
                                setUnlimitedLength(() => ({
                                  ...unlimitedLength,
                                  status: e.target.checked,
                                }))
                              }
                              checked={
                                unlimitedLength?.status === true ? true : false
                              }
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="unlimititedlength">
                              Unlimited Length
                            </label>
                          </div>
                          {/* {unlimitedLength?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row">
                            <div className="mb-3">
                              <label className="col-form-label">Length</label>
                              <input
                                onChange={(e) =>
                                  setUnlimitedLength(() => ({
                                    ...unlimitedLength,
                                    length: e?.target?.value,
                                  }))
                                }
                                type="number"
                                name="length"
                                value={unlimitedLength?.length}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setUnlimitedLength(() => ({
                                    ...unlimitedLength,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={unlimitedLength?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="resolution"
                              onChange={(e) =>
                                setResolution(() => ({
                                  ...resolution,
                                  status: e.target.checked,
                                }))
                              }
                              checked={
                                resolution?.status === true ? true : false
                              }
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="resolution">Resolution</label>
                          </div>
                          {/* {resolution?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row d-block">
                            <div className="d-flex gap-3">
                              {Object.keys(resolution?.resolutions).map(
                                (reslsn) => (
                                  <div
                                    key={reslsn}
                                    className="resolation-opt d-flex align-items-center gap-1"
                                  >
                                    <input
                                      onChange={(e) =>
                                        setResolution((prevState) => ({
                                          ...prevState,
                                          resolutions: {
                                            ...prevState?.resolutions,
                                            [reslsn]: e.target.checked,
                                          },
                                        }))
                                      }
                                      id={reslsn}
                                      type="checkbox"
                                      name={reslsn}
                                      checked={
                                        resolution?.resolutions?.[reslsn]
                                      }
                                      className="form-check-input"
                                    />
                                    <label
                                      htmlFor={reslsn}
                                      className="col-form-label"
                                    >
                                      {reslsn}
                                    </label>
                                  </div>
                                )
                              )}
                            </div>
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setResolution(() => ({
                                    ...resolution,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={resolution?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="watermark"
                              onChange={(e) =>
                                setWatermark(() => ({
                                  ...watermark,
                                  status: e.target.checked,
                                }))
                              }
                              checked={
                                watermark?.status === true ? true : false
                              }
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="watermark">Watermark</label>
                          </div>
                          {/* {watermark?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row">
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setWatermark(() => ({
                                    ...watermark,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={watermark?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="urlSummary"
                              onChange={(e) =>
                                setUrlSummary(() => ({
                                  ...urlSummary,
                                  status: e.target.checked,
                                }))
                              }
                              checked={
                                urlSummary?.status === true ? true : false
                              }
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="urlSummary">Url Summary</label>
                          </div>
                          {/* {urlSummary?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row">
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setUrlSummary(() => ({
                                    ...urlSummary,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={urlSummary?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="gif"
                              onChange={(e) =>
                                setGif(() => ({
                                  ...gif,
                                  status: e.target.checked,
                                }))
                              }
                              checked={gif?.status === true ? true : false}
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="gif">Gif</label>
                          </div>
                          {/* {gif?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row">
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setGif(() => ({
                                    ...gif,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={gif?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="gradient"
                              onChange={(e) =>
                                setGradient(() => ({
                                  ...gradient,
                                  status: e.target.checked,
                                }))
                              }
                              checked={gradient?.status === true ? true : false}
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="gradient">Gradient</label>
                          </div>
                          {/* {gradient?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row">
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setGradient(() => ({
                                    ...gradient,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={gradient?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="emoji"
                              onChange={(e) =>
                                setEmoji(() => ({
                                  ...emoji,
                                  status: e.target.checked,
                                }))
                              }
                              checked={emoji?.status === true ? true : false}
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="emoji">Emoji</label>
                          </div>
                          {/* {emoji?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row">
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setEmoji(() => ({
                                    ...emoji,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={emoji?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="cropPip"
                              onChange={(e) =>
                                setCropPip(() => ({
                                  ...cropPip,
                                  status: e.target.checked,
                                }))
                              }
                              checked={cropPip?.status === true ? true : false}
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="cropPip">Crop PIP</label>
                          </div>
                          {/* {cropPip?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row">
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setCropPip(() => ({
                                    ...cropPip,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={cropPip?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="mediaCropOption"
                              onChange={(e) =>
                                setMediaCropOption(() => ({
                                  ...mediaCropOption,
                                  status: e.target.checked,
                                }))
                              }
                              checked={mediaCropOption?.status === true ? true : false}
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="mediaCropOption">Media Crop Option</label>
                          </div>
                          {/* {mediaCropOption?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row">
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setMediaCropOption(() => ({
                                    ...mediaCropOption,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={mediaCropOption?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="callOut"
                              onChange={(e) =>
                                setCallOut(() => ({
                                  ...callOut,
                                  status: e.target.checked,
                                }))
                              }
                              checked={callOut?.status === true ? true : false}
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="callOut">Callout</label>
                          </div>
                          {/* {cropPip?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row">
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setCallOut(() => ({
                                    ...callOut,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={callOut?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="recovery"
                              onChange={(e) =>
                                setRecovery(() => ({
                                  ...recovery,
                                  status: e.target.checked,
                                }))
                              }
                              checked={recovery?.status === true ? true : false}
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="recovery">Recovery</label>
                          </div>
                          {/* {recovery?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row">
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setRecovery(() => ({
                                    ...recovery,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={recovery?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="usermanagement"
                              onChange={(e) =>
                                setUserManagement(() => ({
                                  ...userManagement,
                                  status: e.target.checked,
                                }))
                              }
                              checked={
                                userManagement?.status === true ? true : false
                              }
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="usermanagement">
                              User Management
                            </label>
                          </div>
                          {/* {userManagement?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row">
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setUserManagement(() => ({
                                    ...userManagement,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={userManagement?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="integrations"
                              onChange={(e) =>
                                setIntegrations(() => ({
                                  ...integrations,
                                  status: e.target.checked,
                                }))
                              }
                              checked={
                                integrations?.status === true ? true : false
                              }
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="integrations">Integrations</label>
                          </div>
                          {/* {integrations?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row">
                            <div className="d-flex flex-wrap gap-3">
                              {Object.keys(integrations?.integration_media)?.map((media) =>
                              (
                                <div
                                  key={media}
                                  className="resolation-opt d-flex align-items-center gap-1"
                                >
                                  <input
                                    onChange={(e) =>
                                      setIntegrations((prevState) => ({
                                        ...prevState,
                                        integration_media: {
                                          ...prevState?.integration_media,
                                          [media]: e.target.checked,
                                        },
                                      }))
                                    }
                                    id={media}
                                    type="checkbox"
                                    name={media}
                                    checked={
                                      integrations?.integration_media?.[media]
                                    }
                                    className="form-check-input"
                                  />
                                  <label
                                    htmlFor={media}
                                    className="col-form-label text-capitalize"
                                  >
                                    {media?.replace('_', " ")}
                                  </label>
                                </div>
                              )
                              )}
                            </div>
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setIntegrations(() => ({
                                    ...integrations,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={integrations?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="speechToText"
                              onChange={(e) =>
                                setSpeechToText(() => ({
                                  ...speechToText,
                                  status: e.target.checked,
                                }))
                              }
                              checked={
                                speechToText?.status === true ? true : false
                              }
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="speechToText">Speech to Text</label>
                          </div>
                          {/* {speechToText?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row">
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setSpeechToText(() => ({
                                    ...speechToText,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={speechToText?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                        <div className="quix-feature-row">
                          <div className="quix-feature-row-title d-flex gap-2">
                            <input
                              id="textToSpeech"
                              onChange={(e) =>
                                setTextToSpeech(() => ({
                                  ...textToSpeech,
                                  status: e.target.checked,
                                }))
                              }
                              checked={
                                textToSpeech?.status === true ? true : false
                              }
                              className="form-check-input"
                              type="checkbox"
                            />
                            <label htmlFor="textToSpeech">Text to Speech</label>
                          </div>
                          {/* {textToSpeech?.status === true && ( */}
                          <div className="quixy-feature-options quix-featured-row">
                            <div className="mb-3">
                              <label className="col-form-label">Message</label>
                              <input
                                onChange={(e) =>
                                  setTextToSpeech(() => ({
                                    ...textToSpeech,
                                    message: e?.target?.value,
                                  }))
                                }
                                type="text"
                                name="message"
                                value={textToSpeech?.message}
                                className="form-control"
                                maxLength={255}
                              />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="quix-form-btn-outer d-flex mt-2 gap-2">
                    <button
                      className="btn theme-cancel-btn"
                      onClick={() => closeModal(false)}
                    >
                      Closed
                    </button>
                    <button
                      className="btn theme-blue-btn"
                      onClick={() => handleSubmit()}
                      disabled={loading}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="quix-dashboard-close"
            onClick={() => closeModal(false)}
          >
            <img src={closeIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  getAllPricingPlansListAdmin,
  updatePricingPlanAdmin,
})(EditPricingPlan);
