import React, { useState } from "react";
import sizeIcon from "../../assets/images/quix-dash-size.png";
import calendarIcon from "../../assets/images/quix-dash-calendar.png";
import saveIcon from "../../assets/images/quix-save-cloud.png";
import deleteIcon from "../../assets/images/quix-dash-delete.png";
import shareLinkIcon from "../../assets/images/quix-share-link.png";
import shareEmailIcon from "../../assets/images/quix-share-email.png";
import resizeIcon from "../../assets/images/quix-dash-resize.png";
import renameIcon from "../../assets/images/quix-dash-rename.png";
import starredIcon from "../../assets/images/quix-dash-starred.png";
import optionsIcon from "../../assets/images/quix-dash-options.png";
import starredGoldenIcon from "../../assets/images/quix-dash-goldenstarred.png";
import { API_URL, localStoragePrefix } from "../../utils/config";
import dateFormat from "dateformat";
import {
  checkEmpty,
  checkServerURL,
  failureMessagePopup,
  sgOpenNewTab,
  sg_DownloadItem,
  sg_format_bytes,
  successMessagePopup,
} from "../../helper/helper";
import ShareLink from "../Modals/ShareLink";
import RenameCard from "../Modals/RenameCard";
import {
  manageScreenShots,
  updateStarredScreenShot,
  manageStarredScreenShorts,
  removeScreenShot,
} from "../../redux/actions/manageScreenshots";
import { useDispatch } from "react-redux";
import { getUserDetailsByID, manageUserProgress } from "../../redux/actions/authentication";
import EditTitleSummaryCard from "../Modals/EditTitleSummaryCard";
import EditTags from "../Modals/EditTags";
import UrlSummary from "../Modals/UrlSummary";

const ImageCard = ({
  cardData,
  isStarredPage,
  isCheckedCard,
  handleChangeChecked,
}) => {
  const itemsPath = cardData?.path && checkServerURL(API_URL, cardData?.path);
  const itemsThumbnailPath =
    cardData?.thumbnail && checkServerURL(API_URL, cardData?.thumbnail);
  const itemsName = cardData?.name && checkEmpty(cardData?.name);
  const itemID = cardData?.id && cardData?.id;
  const itemCreateAt = cardData?.created_at && cardData?.created_at;
  const itemSize = cardData?.file_size && sg_format_bytes(cardData?.file_size);
  const itemIsStarred = cardData?.is_starred && cardData?.is_starred;
  const itemSummary = cardData?.summary ? checkEmpty(cardData?.summary) : "";
  let tagsNames = cardData?.tag_names ? checkEmpty(cardData?.tag_names) : "";

  if (tagsNames !== null && tagsNames !== "") {
    tagsNames = tagsNames?.split(",");
    tagsNames = tagsNames?.map((item) => "#" + item.trim() + " ");
  }
  const [showShareModal, setShareModal] = useState(false);
  const [showUrlSummaryModal, setUrlSummaryModal] = useState(false);
  const [showRenameModal, setRenameModal] = useState(false);
  const [showEditTitleSummaryCard, setShowEditTitleSummaryCard] =
    useState(false);
  const [showEditTagsCard, setEditTagsCard] = useState(false);

  const dispatch = useDispatch();
  // Handles the update of the starred status for a screenshot.
  const userID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );
  const accessToken = localStorage.getItem(
    localStoragePrefix + "screenGeniusAuthToken"
  );
  const handleStarredUpdate = (type) => {
    const payload = {
      status: type,
      id: [itemID],
      access_token: accessToken,
      user_id: Number(userID),
    };
    // Dispatches the updateStarredScreenShot action with the constructed payload.
    dispatch(
      updateStarredScreenShot(payload, (type, res) => {
        if (type === "success") {
          // Dispatches the updateStarredScreenShot action with the constructed payload.
          dispatch(manageStarredScreenShorts(userID, accessToken));
          // Dispatches actions to manage the updated list of starred screenshots and all screenshots.
          dispatch(manageScreenShots(userID, accessToken));
        } else if (type === "fail") {
          failureMessagePopup("Error message", res?.message);
        }
      })
    );
  };
  // remove screenshot
  const handleRemoveScreenShot = (id) => {
    const payload = {
      id: [id],
      access_token: accessToken,
      user_id: Number(userID),
    };
    dispatch(
      removeScreenShot(payload, (type, res) => {
        if (type === "success") {
          successMessagePopup("Success message", "Removed successfully!");
          dispatch(manageStarredScreenShorts(userID, accessToken));
          dispatch(manageScreenShots(userID, accessToken));
          dispatch(manageUserProgress(userID, accessToken, function () { }));
          dispatch(getUserDetailsByID(accessToken, function () { }));
        } else if (type === "fail") {
          failureMessagePopup("Error message", res?.message);
        }
      })
    );
  };

  return (
    <div className="quix-dashboard-content-item-outer" data-video-id={itemID}>
      <input
        type="checkbox"
        className="check-item-type"
        name="check-item-type"
        data-item-type="screenshots"
        data-item-id={itemID}
        data-item-starredval="0"
        data-item-trashval="0"
        onChange={() =>
          handleChangeChecked ? handleChangeChecked(itemID) : ""
        }
        checked={!isCheckedCard ? false : true}
      />
      <div className="quix-dashboard-content-item ">
        <img
          className="quix-dashboard-content-item-img"
          src={itemsThumbnailPath}
        />
        <div className="item-options-icon">
          <img className="item-options-icon-img" src={optionsIcon} />
          <div className="item-options-grid">
            {itemIsStarred === 0 ? (
              <div
                className="item-options-grid-row starred-items"
                onClick={() => handleStarredUpdate(1)}
              >
                <img src={starredIcon} />
                <span>Add to Starred</span>
              </div>
            ) : (
              <div
                className="item-options-grid-row starred-items"
                onClick={() => handleStarredUpdate(0)}
              >
                <img src={starredIcon} />
                <span>Remove Starred</span>
              </div>
            )}
            <div
              onClick={() =>
                setShowEditTitleSummaryCard(!showEditTitleSummaryCard)
              }
              className="item-options-grid-row edit-items"
            >
              <img src={renameIcon} />
              <span>Edit Title/Summary</span>
            </div>
            <div
              onClick={() => setEditTagsCard(!showEditTagsCard)}
              className="item-options-grid-row edit-items"
            >
              <img src={renameIcon} />
              <span>Edit Tags</span>
            </div>
            {!isStarredPage ? (
              <>
                <div
                  className="item-options-grid-row rename-items"
                  onClick={() => setRenameModal(!showRenameModal)}
                >
                  <img src={renameIcon} />
                  <span>Rename</span>
                </div>
                <div
                  className="item-options-grid-row resize-items"
                  onClick={() => sgOpenNewTab(itemsPath)}
                >
                  <img src={resizeIcon} />
                  <span>View in new Tab</span>
                </div>
                <div
                  className="item-options-grid-row trash-items"
                  // onClick={() => setUrlSummaryModal(!showUrlSummaryModal)}
                  title="Coming Soon"
                  style={{ opacity: "0.5" }}
                >
                  <img src={shareLinkIcon} />
                  <span>URL Summary</span>
                </div>
                <div
                  className="item-options-grid-row trash-items"
                  onClick={() => setShareModal(!showShareModal)}
                >
                  <img src={shareEmailIcon} />
                  <span>Share Screenshot</span>
                </div>
              </>
            ) : (
              ""
            )}

            <div
              className="item-options-grid-row trash-items"
              onClick={() => handleRemoveScreenShot(itemID)}
            >
              <img src={deleteIcon} />
              <span>Delete</span>
            </div>
            {!isStarredPage ? (
              <>
                <div
                  className="item-options-grid-row resize-items"
                  onClick={() =>
                    sg_DownloadItem(
                      "image",
                      itemsPath,
                      itemsName,
                      itemID,
                      "png"
                    )
                  }
                >
                  <img src={saveIcon} />
                  <span>Download(PNG)</span>
                </div>
                <div
                  className="item-options-grid-row resize-items"
                  onClick={() =>
                    sg_DownloadItem(
                      "image",
                      itemsPath,
                      itemsName,
                      itemID,
                      "jpg"
                    )
                  }
                >
                  <img src={saveIcon} />
                  <span>Download(JPEG)</span>
                </div>
                <div
                  className="item-options-grid-row resize-items"
                  onClick={() =>
                    sg_DownloadItem(
                      "image",
                      itemsPath,
                      itemsName,
                      itemID,
                      "pdf"
                    )
                  }
                >
                  <img src={saveIcon} />
                  <span>Download(PDF)</span>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="item-footer-info">
        <div className="item-footer-inner">
          <div className="item-title" title={itemsName}>
            {itemsName}
          </div>

          <div className="item-bottom-details">
            <div
              className="item-date"
              style={{ width: "70%", fontSize: "12px" }}
            >
              <img src={calendarIcon} />
              <span>
                {itemCreateAt &&
                  dateFormat(itemCreateAt, "dd/mm/yyyy hh:MM TT")}
              </span>
            </div>
            <div
              className="item-date"
              style={{ width: "30%", fontSize: "12px" }}
            >
              <img src={sizeIcon} />
              <span>{itemSize}</span>
            </div>
          </div>
          {itemIsStarred === 1 && (
            <div className="starred-image-outer">
              <img src={starredGoldenIcon} />
            </div>
          )}
        </div>
        {itemSummary && (
          <div className="quix-card-summary-dec">
            <p>{itemSummary}</p>
          </div>
        )}
        {tagsNames && (
          <div className="quix-card-tags">
            <span>{tagsNames}</span>
          </div>
        )}
      </div>
      {showEditTagsCard && (
        <EditTags
          showModal={showEditTagsCard}
          closeModal={setEditTagsCard}
          cardData={cardData}
          cardType={"screenshot"}
        />
      )}
      {showEditTitleSummaryCard && (
        <EditTitleSummaryCard
          showModal={showEditTitleSummaryCard}
          closeModal={setShowEditTitleSummaryCard}
          itemType={"image"}
          itemID={itemID}
          userID={userID}
          cardData={cardData}
        />
      )}
      {showUrlSummaryModal && (
        <UrlSummary
          showModal={showUrlSummaryModal}
          closeModal={setUrlSummaryModal}
        />
      )}
      {showShareModal && (
        <ShareLink
          showModal={showShareModal}
          closeModal={setShareModal}
          itemID={itemID}
          path={itemsPath}
          cardType={"image"}
          cardData={cardData}
        />
      )}
      {showRenameModal && (
        <RenameCard
          showModal={showRenameModal}
          closeModal={setRenameModal}
          itemName={itemsName}
          itemType={"image"}
          itemID={itemID}
          userID={userID}
        />
      )}
    </div>
  );
};

export default ImageCard;
