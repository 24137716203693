import React, { useEffect, useState } from "react";
import AdminLayout from "../../layout/adminLayout";
import { connect, useDispatch } from "react-redux";
import {
  getGeneralSettingDetailsAdmin,
  updateGeneralSettingsAdmin,
} from "../../../redux/actions/admin/generalSettings";
import { localStoragePrefix } from "../../../utils/config";
import {
  errorMessageModal,
  successMessageModal,
} from "../../components/Modal/MessagesModal";

const ManageGeneralSettings = ({ generalSettinDetails }) => {
  const dispatch = useDispatch();
  const adminID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );

  useEffect(() => {
    dispatch(getGeneralSettingDetailsAdmin(Number(adminID)));
  }, []);

  const initialValues = {
    facebook: {
      facebook_client_id: "",
      facebook_client_secret: "",
      facebook_callback: "",
      facebook_status: "",
    },
    google: {
      google_client_id: "",
      google_client_secret: "",
      google_client_callback: "",
      google_status: "",
    },
    linkedin: {
      linkedin_client_id: "",
      linkedin_secret_key: "",
      linkedin_callback: "",
      linkedin_status: "",
    },
    mail: {
      web_mail_user: "",
      web_mail_password: "",
      mail_url: "",
      mail_header: "",
      mail_name: "",
      image_url: "",
      mail_status: "",
    },
    notion: {
      notion_client_id: "",
      notion_client_secret: "",
      notion_status: "",
    },
    slack: {
      slack_client_id: "",
      slack_client_secret: "",
      slack_callback: "",
      slack_status: "",
    },
    microsoft_azure: {
      microsoftazure_client_id: "",
      microsoftazure_client_secret: "",
      microsoftazure_callback: "",
      microsoftazure_status: "",
    },
    stripe: {
      stripe_api_key: "",
      stripe_api_secret: "",
      stripe_status: "",
    },
    twitter: {
      twitter_client_id: "",
      twitter_client_secret: "",
      twitter_callback: "",
      twitter_status: "",
    },
    bard: {
      bard_api_key: "",
      bard_status: "",
    },
    chat_gpt: {
      chat_gpt_api_key: "",
      chat_gpt_status: "",
    },
    dropbox: {
      dropbox_api_key: "",
      dropbox_api_secret: "",
      dropbox_callback: "",
      dropbox_status: "",
    },
    evernote: {
      consumer_key: "",
      consumer_secret: "",
      evernote_status: "",
    },
  };

  const [formData, setFormData] = useState(initialValues);
  const generalSettingData =
    generalSettinDetails?.data && generalSettinDetails?.data?.[0];
  const bardDetails =
    generalSettingData?.bard && JSON.parse(generalSettingData?.bard);
  const chatGptDetails =
    generalSettingData?.chatGpt && JSON.parse(generalSettingData?.chatGpt);
  const facebookDetails =
    generalSettingData?.facebook && JSON.parse(generalSettingData?.facebook);
  const googleDetails =
    generalSettingData?.google && JSON.parse(generalSettingData?.google);
  const linkedinDetails =
    generalSettingData?.linkedin && JSON.parse(generalSettingData?.linkedin);
  const mailDetails =
    generalSettingData?.mail && JSON.parse(generalSettingData?.mail);
  const notionDetails =
    generalSettingData?.notion && JSON.parse(generalSettingData?.notion);
  const slackDetails =
    generalSettingData?.slack && JSON.parse(generalSettingData?.slack);
  const microsoftAzureDetails =
    generalSettingData?.microsoftazure &&
    JSON.parse(generalSettingData?.microsoftazure);
  const stripeDetails =
    generalSettingData?.stripe && JSON.parse(generalSettingData?.stripe);
  const twitterDetails =
    generalSettingData?.twitter && JSON.parse(generalSettingData?.twitter);
  const dropboxDetails =
    generalSettingData?.dropbox && JSON.parse(generalSettingData?.dropbox);
  const evernoteDetails =
    generalSettingData?.evernote && JSON.parse(generalSettingData?.evernote);

  const setDefaultSettings = () => {
    setFormData({
      facebook: {
        facebook_client_id: facebookDetails?.facebook_client_id || "",
        facebook_client_secret: facebookDetails?.facebook_client_secret || "",
        facebook_callback: facebookDetails?.facebook_callback || "",
        facebook_status: facebookDetails?.facebook_status || "",
      },
      google: {
        google_client_id: googleDetails?.google_client_id || "",
        google_client_secret: googleDetails?.google_client_secret || "",
        google_client_callback: googleDetails?.google_client_callback,
        google_status: googleDetails?.google_status,
      },
      linkedin: {
        linkedin_client_id: linkedinDetails?.linkedin_client_id || "",
        linkedin_secret_key: linkedinDetails?.linkedin_secret_key || "",
        linkedin_callback: linkedinDetails?.linkedin_callback || "",
        linkedin_status: linkedinDetails?.linkedin_status || "",
      },
      mail: {
        web_mail_user: mailDetails?.web_mail_user || "",
        web_mail_password: mailDetails?.web_mail_password || "",
        mail_url: mailDetails?.mail_url || "",
        mail_header: mailDetails?.mail_header || "",
        mail_name: mailDetails?.mail_name || "",
        image_url: mailDetails?.image_url || "",
        mail_status: mailDetails?.mail_status || "",
      },
      notion: {
        notion_client_id: notionDetails?.notion_client_id || "",
        notion_client_secret: notionDetails?.notion_client_secret || "",
        notion_status: notionDetails?.notion_status || "",
      },
      slack: {
        slack_client_id: slackDetails?.slack_client_id || "",
        slack_client_secret: slackDetails?.slack_client_secret || "",
        slack_callback: slackDetails?.slack_callback || "",
        slack_status: slackDetails?.slack_status || "",
      },
      microsoft_azure: {
        microsoftazure_client_id:
          microsoftAzureDetails?.microsoftazure_client_id || "",
        microsoftazure_client_secret:
          microsoftAzureDetails?.microsoftazure_client_secret || "",
        microsoftazure_callback:
          microsoftAzureDetails?.microsoftazure_callback || "",
        microsoftazure_status:
          microsoftAzureDetails?.microsoftazure_status || "",
      },
      stripe: {
        stripe_api_key: stripeDetails?.stripe_api_key || "",
        stripe_api_secret: stripeDetails?.stripe_api_secret || "",
        stripe_status: stripeDetails?.stripe_status || "",
      },
      twitter: {
        twitter_client_id: twitterDetails?.twitter_client_id || "",
        twitter_client_secret: twitterDetails?.twitter_client_secret || "",
        twitter_callback: twitterDetails?.twitter_callback || "",
        twitter_status: twitterDetails?.twitter_status || "",
      },
      bard: {
        bard_api_key: bardDetails?.bard_api_key || "",
        bard_status: bardDetails?.bard_status || "",
      },
      chat_gpt: {
        chat_gpt_api_key: chatGptDetails?.chat_gpt_api_key || "",
        chat_gpt_status: chatGptDetails?.chat_gpt_status || "",
      },
      dropbox: {
        dropbox_api_key: dropboxDetails?.dropbox_api_key || "",
        dropbox_api_secret: dropboxDetails?.dropbox_api_secret || "",
        dropbox_callback: dropboxDetails?.dropbox_callback || "",
        dropbox_status: dropboxDetails?.dropbox_status || "",
      },
      evernote: {
        consumer_key: evernoteDetails?.consumer_key || "",
        consumer_secret: evernoteDetails?.consumer_secret || "",
        evernote_status: evernoteDetails?.evernote_status || "",
      },
    });
  };

  useEffect(() => {
    setDefaultSettings();
  }, [generalSettingData]);

  const handleSubmit = () => {
    const payload = {
      ...formData,
      admin_id: Number(adminID),
    };
    dispatch(
      updateGeneralSettingsAdmin(payload, (type, res) => {
        if (type === "success") {
          successMessageModal(
            "General Settings Updated Successfully",
            "General Settings has been Updated Successfully"
          );
        } else if (type === "failed") {
          errorMessageModal("General Settings Updated", res?.message);
        }
      })
    );
  };

  const handleCancel = () => {
    setDefaultSettings();
  };

  return (
    <div className="manage-general-settings">
      <AdminLayout pageTitle={"general-settings"}>
        <div className="extension-settings-content settings-content">
          <div className="table-management-section d-flex justify-content-between col-md-10">
            <div className="management-header mb-5">
              <h4>General Settings</h4>
              <p>
                You can manage basic settings like video or screenshot
                resolutions & video <br />
                recording delay settings.
              </p>
            </div>
            <div className="ext-setting-btns">
              <button
                className="btn theme-blue-btn me-2"
                onClick={() => handleSubmit()}
              >
                Save & Continue
              </button>
              <button
                className="btn theme-cancel-btn"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="general-settings-outer">
            <div className="card-column">
              {/* google card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header d-flex align-items-center gap-2">
                  <input
                    id="google"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        google: {
                          ...formData?.google,
                          google_status: e.target.checked,
                        },
                      })
                    }
                    checked={
                      formData?.google?.google_status === true ? true : false
                    }
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label htmlFor="google" className="col-form-label">
                    <h5 className="title">Google</h5>
                  </label>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="googleClientID"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Google Client Id
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="googleClientID"
                          id="googleClientID"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              google: {
                                ...formData?.google,
                                google_client_id: e.target.value,
                              },
                            })
                          }
                          value={formData?.google?.google_client_id}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="googleClientSecret"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Google Client Secret
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="googleClientSecret"
                          id="googleClientSecret"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              google: {
                                ...formData?.google,
                                google_client_secret: e.target.value,
                              },
                            })
                          }
                          value={formData?.google?.google_client_secret}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="googleClientCallBack"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Google Client CallBack
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="googleClientCallBack"
                          id="googleClientCallBack"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              google: {
                                ...formData?.google,
                                google_client_callback: e.target.value,
                              },
                            })
                          }
                          value={formData?.google?.google_client_callback}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* facebook card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header d-flex align-items-center gap-2">
                  <input
                    id="facebook"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        facebook: {
                          ...formData?.facebook,
                          facebook_status: e.target.checked,
                        },
                      })
                    }
                    checked={
                      formData?.facebook?.facebook_status === true
                        ? true
                        : false
                    }
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label htmlFor="facebook" className="col-form-label">
                    <h5 className="title">Facebook</h5>
                  </label>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="facebook_client_id"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Facebook Client Id
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="facebook_client_id"
                          id="facebook_client_id"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              facebook: {
                                ...formData?.facebook,
                                facebook_client_id: e.target.value,
                              },
                            })
                          }
                          value={formData?.facebook?.facebook_client_id}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="facebook_client_secret"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Facebook Client Secret
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="facebook_client_secret"
                          id="facebook_client_secret"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              facebook: {
                                ...formData?.facebook,
                                facebook_client_secret: e.target.value,
                              },
                            })
                          }
                          value={formData?.facebook?.facebook_client_secret}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="facebook_callback"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Facebook Callback
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="facebook_callback"
                          id="facebook_callback"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              facebook: {
                                ...formData?.facebook,
                                facebook_callback: e.target.value,
                              },
                            })
                          }
                          value={formData?.facebook?.facebook_callback}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* linkedin card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header d-flex align-items-center gap-2">
                  <input
                    id="linkedin"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        linkedin: {
                          ...formData?.linkedin,
                          linkedin_status: e.target.checked,
                        },
                      })
                    }
                    checked={
                      formData?.linkedin?.linkedin_status === true
                        ? true
                        : false
                    }
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label htmlFor="linkedin" className="col-form-label">
                    <h5 className="title">Linkedin</h5>
                  </label>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="linkedin_client_id"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Linkedin Client Id
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="linkedin_client_id"
                          id="linkedin_client_id"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              linkedin: {
                                ...formData?.linkedin,
                                linkedin_client_id: e.target.value,
                              },
                            })
                          }
                          value={formData?.linkedin?.linkedin_client_id}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="linkedin_secret_key"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Linkedin Secret Key
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="linkedin_secret_key"
                          id="linkedin_secret_key"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              linkedin: {
                                ...formData?.linkedin,
                                linkedin_secret_key: e.target.value,
                              },
                            })
                          }
                          value={formData?.linkedin?.linkedin_secret_key}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="linkedin_callback"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Linkedin Callback
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="linkedin_callback"
                          id="linkedin_callback"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              linkedin: {
                                ...formData?.linkedin,
                                linkedin_callback: e.target.value,
                              },
                            })
                          }
                          value={formData?.linkedin?.linkedin_callback}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* chatGtp card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header d-flex align-items-center gap-2">
                  <input
                    id="chat_gpt"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        chat_gpt: {
                          ...formData?.chat_gpt,
                          chat_gpt_status: e.target.checked,
                        },
                      })
                    }
                    checked={
                      formData?.chat_gpt?.chat_gpt_status === true
                        ? true
                        : false
                    }
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label htmlFor="chat_gpt" className="col-form-label">
                    <h5 className="title">Chat GPT</h5>
                  </label>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="chat_gpt_api_key"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Chat GPT Api Key
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="chat_gpt_api_key"
                          id="chat_gpt_api_key"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              chat_gpt: {
                                ...formData?.chat_gpt,
                                chat_gpt_api_key: e.target.value,
                              },
                            })
                          }
                          value={formData?.chat_gpt?.chat_gpt_api_key}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* bard card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header d-flex align-items-center gap-2">
                  <input
                    id="bard"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        bard: {
                          ...formData?.bard,
                          bard_status: e.target.checked,
                        },
                      })
                    }
                    checked={
                      formData?.bard?.bard_status === true ? true : false
                    }
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label htmlFor="bard" className="col-form-label">
                    <h5 className="title">Bard</h5>
                  </label>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="bard_api_key"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Bard Api Key
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="bard_api_key"
                          id="bard_api_key"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              bard: {
                                ...formData?.bard,
                                bard_api_key: e.target.value,
                              },
                            })
                          }
                          value={formData?.bard?.bard_api_key}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* dropbox */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header d-flex align-items-center gap-2">
                  <input
                    id="dropbox"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        dropbox: {
                          ...formData?.dropbox,
                          dropbox_status: e.target.checked,
                        },
                      })
                    }
                    checked={
                      formData?.dropbox?.dropbox_status === true ? true : false
                    }
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label htmlFor="dropbox" className="col-form-label">
                    <h5 className="title">DropBox</h5>
                  </label>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="dropbox_api_key"
                        className="col-sm-12 col-form-label text-start"
                      >
                        DropBox Api Key
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="dropbox_api_key"
                          id="dropbox_api_key"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              dropbox: {
                                ...formData?.dropbox,
                                dropbox_api_key: e.target.value,
                              },
                            })
                          }
                          value={formData?.dropbox?.dropbox_api_key}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="dropbox_api_secret"
                        className="col-sm-12 col-form-label text-start"
                      >
                        DropBox Api Secret
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="dropbox_api_secret"
                          id="dropbox_api_secret"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              dropbox: {
                                ...formData?.dropbox,
                                dropbox_api_secret: e.target.value,
                              },
                            })
                          }
                          value={formData?.dropbox?.dropbox_api_secret}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="dropbox_callback"
                        className="col-sm-12 col-form-label text-start"
                      >
                        DropBox Callback
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="dropbox_callback"
                          id="dropbox_callback"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              dropbox: {
                                ...formData?.dropbox,
                                dropbox_callback: e.target.value,
                              },
                            })
                          }
                          value={formData?.dropbox?.dropbox_callback}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Evernote */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header d-flex align-items-center gap-2">
                  <input
                    id="evernote"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        evernote: {
                          ...formData?.evernote,
                          evernote_status: e.target.checked,
                        },
                      })
                    }
                    checked={
                      formData?.evernote?.evernote_status === true
                        ? true
                        : false
                    }
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label htmlFor="evernote" className="col-form-label">
                    <h5 className="title">Evernote</h5>
                  </label>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="evernote_consumer_key"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Evernote Consumer Key
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="evernote_consumer_key"
                          id="evernote_consumer_key"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              evernote: {
                                ...formData?.evernote,
                                consumer_key: e.target.value,
                              },
                            })
                          }
                          value={formData?.evernote?.consumer_key}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="evenote_consumer_secret"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Evernote Consumer Secret
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="evenote_consumer_secret"
                          id="evenote_consumer_secret"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              evernote: {
                                ...formData?.evernote,
                                consumer_secret: e.target.value,
                              },
                            })
                          }
                          value={formData?.evernote?.consumer_secret}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-column">
              {/* mail card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header d-flex align-items-center gap-2">
                  <input
                    id="mail"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        mail: {
                          ...formData?.mail,
                          mail_status: e.target.checked,
                        },
                      })
                    }
                    checked={
                      formData?.mail?.mail_status === true ? true : false
                    }
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label htmlFor="mail" className="col-form-label">
                    <h5 className="title">Mail</h5>
                  </label>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="web_mail_user"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Mail User Email
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="web_mail_user"
                          id="web_mail_user"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              mail: {
                                ...formData?.mail,
                                web_mail_user: e.target.value,
                              },
                            })
                          }
                          value={formData?.mail?.web_mail_user}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="mailPassword"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Password
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type={"text"}
                          name="mailPassword"
                          id="mailPassword"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              mail: {
                                ...formData?.mail,
                                web_mail_password: e.target.value,
                              },
                            })
                          }
                          value={formData?.mail?.web_mail_password}
                          autoComplete={false}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="mail_url"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Mail Url
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="mail_url"
                          id="mail_url"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              mail: {
                                ...formData?.mail,
                                mail_url: e.target.value,
                              },
                            })
                          }
                          value={formData?.mail?.mail_url}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="mail_header"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Mail Header
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="mail_header"
                          id="mail_header"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              mail: {
                                ...formData?.mail,
                                mail_header: e.target.value,
                              },
                            })
                          }
                          value={formData?.mail?.mail_header}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="mail_name"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Mail Name
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="mail_name"
                          id="mail_name"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              mail: {
                                ...formData?.mail,
                                mail_name: e.target.value,
                              },
                            })
                          }
                          value={formData?.mail?.mail_name}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="image_url"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Image Url
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="image_url"
                          id="image_url"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              mail: {
                                ...formData?.mail,
                                image_url: e.target.value,
                              },
                            })
                          }
                          value={formData?.mail?.image_url}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* stripe card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header d-flex align-items-center gap-2">
                  <input
                    id="stripe"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        stripe: {
                          ...formData?.stripe,
                          stripe_status: e.target.checked,
                        },
                      })
                    }
                    checked={
                      formData?.stripe?.stripe_status === true ? true : false
                    }
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label htmlFor="stripe" className="col-form-label">
                    <h5 className="title">Stripe</h5>
                  </label>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="stripeApikey"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Stripe Api Key
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="stripeApikey"
                          id="stripeApikey"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              stripe: {
                                ...formData?.stripe,
                                stripe_api_key: e.target.value,
                              },
                            })
                          }
                          value={formData?.stripe?.stripe_api_key}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="stripe_api_secret"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Stripe Api Secret
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="stripe_api_secret"
                          id="stripe_api_secret"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              stripe: {
                                ...formData?.stripe,
                                stripe_api_secret: e.target.value,
                              },
                            })
                          }
                          value={formData?.stripe?.stripe_api_secret}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* twitter card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header d-flex align-items-center gap-2">
                  <input
                    id="twitter"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        twitter: {
                          ...formData?.twitter,
                          twitter_status: e.target.checked,
                        },
                      })
                    }
                    checked={
                      formData?.twitter?.twitter_status === true ? true : false
                    }
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label htmlFor="twitter" className="col-form-label">
                    <h5 className="title">Twitter</h5>
                  </label>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="twitter_client_id"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Twitter Api Key
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="twitter_client_id"
                          id="twitter_client_id"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              twitter: {
                                ...formData?.twitter,
                                twitter_client_id: e.target.value,
                              },
                            })
                          }
                          value={formData?.twitter?.twitter_client_id}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="twitter_client_secret"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Twitter Api Secret
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="twitter_client_secret"
                          id="twitter_client_secret"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              twitter: {
                                ...formData?.twitter,
                                twitter_client_secret: e.target.value,
                              },
                            })
                          }
                          value={formData?.twitter?.twitter_client_secret}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="twitter_callback"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Twitter Callback
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="twitter_callback"
                          id="twitter_callback"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              twitter: {
                                ...formData?.twitter,
                                twitter_callback: e.target.value,
                              },
                            })
                          }
                          value={formData?.twitter?.twitter_callback}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* notion card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header d-flex align-items-center gap-2">
                  <input
                    id="notion"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        notion: {
                          ...formData?.notion,
                          notion_status: e.target.checked,
                        },
                      })
                    }
                    checked={
                      formData?.notion?.notion_status === true ? true : false
                    }
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label htmlFor="notion" className="col-form-label">
                    <h5 className="title">Notion</h5>
                  </label>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="notion_client_id"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Notion Client Id
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="notion_client_id"
                          id="notion_client_id"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              notion: {
                                ...formData?.notion,
                                notion_client_id: e.target.value,
                              },
                            })
                          }
                          value={formData?.notion?.notion_client_id}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="notion_client_secret"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Notion Client Secret
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="notion_client_secret"
                          id="notion_client_secret"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              notion: {
                                ...formData?.notion,
                                notion_client_secret: e.target.value,
                              },
                            })
                          }
                          value={formData?.notion?.notion_client_secret}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* notion card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header d-flex align-items-center gap-2">
                  <input
                    id="slack"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        slack: {
                          ...formData?.slack,
                          slack_status: e.target.checked,
                        },
                      })
                    }
                    checked={
                      formData?.slack?.slack_status === true ? true : false
                    }
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label htmlFor="slack" className="col-form-label">
                    <h5 className="title">Slack</h5>
                  </label>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="slack_client_id"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Slack Client Id
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="slack_client_id"
                          id="slack_client_id"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              slack: {
                                ...formData?.slack,
                                slack_client_id: e.target.value,
                              },
                            })
                          }
                          value={formData?.slack?.slack_client_id}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="slack_client_secret"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Slack Client Secret
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="slack_client_secret"
                          id="slack_client_secret"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              slack: {
                                ...formData?.slack,
                                slack_client_secret: e.target.value,
                              },
                            })
                          }
                          value={formData?.slack?.slack_client_secret}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="slack_callback"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Slack Callback
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="slack_callback"
                          id="slack_callback"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              slack: {
                                ...formData?.slack,
                                slack_callback: e.target.value,
                              },
                            })
                          }
                          value={formData?.slack?.slack_callback}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Microsoft Azure */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header d-flex align-items-center gap-2">
                  <input
                    id="microsoft_azure"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        microsoft_azure: {
                          ...formData?.microsoft_azure,
                          microsoftazure_status: e.target.checked,
                        },
                      })
                    }
                    checked={
                      formData?.microsoft_azure?.microsoftazure_status === true
                        ? true
                        : false
                    }
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label htmlFor="microsoft_azure" className="col-form-label">
                    <h5 className="title">Microsoft Azure</h5>
                  </label>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="microsoftazure_client_id"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Microsoft Azure Client Id
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="microsoftazure_client_id"
                          id="microsoftazure_client_id"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              microsoft_azure: {
                                ...formData?.microsoft_azure,
                                microsoftazure_client_id: e.target.value,
                              },
                            })
                          }
                          value={
                            formData?.microsoft_azure?.microsoftazure_client_id
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="microsoftazure_client_secret"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Microsoft Azure Client Secret
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="microsoftazure_client_secret"
                          id="microsoftazure_client_secret"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              microsoft_azure: {
                                ...formData?.microsoft_azure,
                                microsoftazure_client_secret: e.target.value,
                              },
                            })
                          }
                          value={
                            formData?.microsoft_azure
                              ?.microsoftazure_client_secret
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="microsoftazure_callback"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Microsoft Azure Callback
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="microsoftazure_callback"
                          id="microsoftazure_callback"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              microsoft_azure: {
                                ...formData?.microsoft_azure,
                                microsoftazure_callback: e.target.value,
                              },
                            })
                          }
                          value={
                            formData?.microsoft_azure?.microsoftazure_callback
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </div>
  );
};

// generalSettinDetails

const mapStateToProps = (state) => ({
  generalSettinDetails: state?.general_setting?.details,
});

// export default ManageGeneralSettings;

export default connect(mapStateToProps, { getGeneralSettingDetailsAdmin })(
  ManageGeneralSettings
);
