export const WEB_URL = "https://vizam.ai";
export const API_URL = "https://api.vizam.ai";
export const REDIRECT_URL = "https://vizam.ai/dashboard";
export const SENDER_NAME = "Vizam send you a mail.";
export const SENDER_EMAIL = "support@vizam.ai";
export const LIMIT_OF_SCREENSHOTS = 30;
export const LIMIT_OF_VIDEOS = 15;
export const localStoragePrefix = 'SG_';
export const GOOGLE_CLIENT_ID = "289798756049-kb8flpfrcnsib8kviihvi4ijo50njb2b.apps.googleusercontent.com";
export const GOOGLE_CLIENT_SECRET = "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";
export const FACEBOOK_APP_ID = "3147454238896419";
export const LINKED_IN_CLIENT_ID = "770lg3auun6mvh";
export const LINKEDIN_SECRET_ID = "46LgxOUjQZmrVp6S";
export const LINKED_IN_REDIRECT_URL = "https://vizam.ai/auth/linkedin";
export const TWITTER_CLIENT_ID = "HoOBXK2dGmtUmrnlaEpOtZ1CU";