import React from 'react';

const MediaCard = ({ mediaIcon, className, style, title, onClick, actionType, isPaid }) => {
    return (
        <div
            className={className}
            style={style}
            onClick={onClick}
            action-type={actionType}
        >
            <img src={mediaIcon} title={title} />
            {isPaid === false && (
                <span className='user-paid-feature'>🔒</span>
            )}
        </div>
    );
};

MediaCard.dafaultProps = {
    mediaIcon: '',
    className: '',
    style: {},
    title: '',
    onClick: () => { },
    actionType: '',
    isPaid: false
}

export default MediaCard;