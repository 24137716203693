// Edit pricing plan validations
export const adminCreatePricingPlanSachema = ({ formData, setFormErrors }) => {
    const { price, duration, price_string } = formData;
    let valid = true;
    let numberRegex = /^\d*$/

    const errors = {
        priceError: "",
        durationError: "",
        priceStringError: ""
    };

    if (price === "") {
        errors.priceError = "Price cannot be empty!";
        valid = false;
    } else if (!numberRegex.test(price)) {
        errors.priceError = "Please enter only numerical value!";
        valid = false;
    }

    if (!duration) {
        errors.durationError = "Duration cannot be empty!";
        valid = false;
    } else if (!numberRegex.test(duration)) {
        errors.durationError = "Please enter only numerical value!";
        valid = false;
    }

    if (!price_string) {
        errors.priceStringError = "Price String cannot be empty!";
        valid = false;
    }

    setFormErrors(errors);
    return valid;
}

// Edit pricing plan validations
export const adminEditPricingPlanSachema = ({ formData, setFormErrors }) => {
    const { price, duration, price_string } = formData;
    let valid = true;
    let numberRegex = /^\d*$/

    const errors = {
        priceError: "",
        durationError: "",
        priceStringError: ""
    };

    if (price === undefined || price === null || price === '') {
        errors.priceError = "Price cannot be empty!";
        valid = false;
    } else if (!numberRegex.test(price)) {
        errors.priceError = "Please enter only numerical value!";
        valid = false;
    }

    if (!duration) {
        errors.durationError = "Duration cannot be empty!";
        valid = false;
    } else if (!numberRegex.test(duration)) {
        errors.durationError = "Please enter only numerical value!";
        valid = false;
    }

    if (!price_string) {
        errors.priceStringError = "Price String cannot be empty!";
        valid = false;
    }

    setFormErrors(errors);
    return valid;
}