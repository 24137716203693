import React, { useState } from "react";
import loaderIcon from "../../assets/images/light-loader.gif";
import closeIcon from "../../assets/images/quix-close.png";
import { shareByEmailScreenShot } from "../../redux/actions/manageScreenshots";
import { useDispatch } from "react-redux";
import { shareByEmailVideo } from "../../redux/actions/manageVideos";
import { failureMessagePopup, successMessagePopup } from "../../helper/helper";
import { localStoragePrefix } from "../../utils/config";

const ShareByEmail = ({ showModal, closeModal, itemType, itemID, userID }) => {
  const type = itemType;
  const id = itemID;
  const initialValues = {
    toNameFeedback: "",
    toEmail: "",
    emailMessage: "",
  };

  const UserID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );

  const accessToken = localStorage.getItem(
    localStoragePrefix + "screenGeniusAuthToken"
  );

  const [loading, setLoading] = useState(false);
  const [senderDetails, setSenderDetail] = useState(initialValues);
  const dispatch = useDispatch();
  // 
  const [formErrors, setFormErrors] = useState();

  const ShareEmailValidaionSchema = ({ formData, setFormErrors }) => {
    const { emailMessage, toEmail, toNameFeedback } = formData;
    let regex =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let valid = true;

    const errors = {
      nameError: "",
      emailError: "",
      emailMessageError: "",
    };

    if (!toNameFeedback?.trim('')) {
      errors.nameError = "First name cannot be empty!";
      valid = false;
    }

    if (!toEmail?.trim('')) {
      errors.emailError = "Email cannot be empty!";
      valid = false;
    } else if (!regex.test(toEmail)) {
      errors.emailError = "Invalid email address!";
      valid = false;
    }

    if (!emailMessage?.trim('')) {
      errors.emailMessageError = "Message cannot be empty!";
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };
  // 
  const handleChange = (event) => {
    const { name, value } = event.target;
    setSenderDetail({
      ...senderDetails,
      [name]: value,
    });
  };

  const handleSendEmail = () => {
    if (ShareEmailValidaionSchema({ formData: senderDetails, setFormErrors })) {
      setLoading(true);
      const payload = {
        id: id,
        toname: senderDetails?.toNameFeedback,
        emailId: senderDetails?.toEmail,
        userMessage: senderDetails?.emailMessage,
        userId: UserID,
        access_token: accessToken,
      };
      if (type === "image") {
        // Dispatch action to share screenshot via email
        dispatch(
          shareByEmailScreenShot(payload, (type, res) => {
            if (type === "success") {
              successMessagePopup(
                "Success Message!",
                "Shared via email successfully."
              );
              setLoading(false);
              closeModal(false);
            } else {
              failureMessagePopup("Field Message!", "Something went wrong.");
              setLoading(false);
              closeModal(false);
            }
          })
        );
      } else {
        // Dispatch action to share video via email
        dispatch(
          shareByEmailVideo(payload, (type, res) => {
            if (type === "success") {
              setLoading(false);
              closeModal(false);
              successMessagePopup(
                "Success Message!",
                "Shared via email successfully."
              );
            } else {
              failureMessagePopup("Field Message!", "Something went wrong.");
              setLoading(false);
              closeModal(false);
            }
          })
        );
      }
    }
  };

  return (
    <>
      <div
        id="quix-dashboard-overlay"
        style={{
          display: !showModal ? "none" : "block",
        }}
      ></div>
      <div id="quix-dashboard-popup">
        <div className="quix-dashboard-popup-inner social-share-email">
          <div className="quix-dashboard-popup-title">
            <div className="quix-dashboard-popup-title-inner">
              <span>Send through Email</span>
            </div>
          </div>
          <div className="quix-dashboard-popup-body mt-0">
            <div className="quix-dashboard-popup-body-inner text-start">
              <div className="mb-2">
                <input
                  className="form-control"
                  type="text"
                  name="toNameFeedback"
                  placeholder="Enter Your First Name"
                  onChange={(e) => handleChange(e)}
                  value={senderDetails?.toNameFeedback}
                  maxLength={255}
                />
                {formErrors?.nameError && (
                  <p className="text-danger account-dec m-0">
                    {formErrors?.nameError}
                  </p>
                )}
              </div>
              <div className="mb-2">
                <input
                  type="text"
                  name="toEmail"
                  className="form-control"
                  placeholder="To Email"
                  onChange={(e) => handleChange(e)}
                  value={senderDetails?.toEmail}
                  maxLength={255}
                />
                {formErrors?.emailError && (
                  <p className="text-danger account-dec m-0">
                    {formErrors?.emailError}
                  </p>
                )}
              </div>
              <div className="">
                <textarea
                  className="form-control mx-auto"
                  id="email-message"
                  placeholder="Message"
                  maxLength="300"
                  name="emailMessage"
                  onChange={(e) => handleChange(e)}
                  value={senderDetails?.emailMessage}
                ></textarea>
                <p className="message-counter"></p>
                {formErrors?.emailMessageError && (
                  <p className="text-danger account-dec m-0">
                    {formErrors?.emailMessageError}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="quix-dashboard-popup-footer mt-0">
            <div className="quix-dashboard-popup-footer-inner position-relative">
              <div className="quix-form-filed text-center">
                <button
                  className="btn form-action-button form-cancel-button"
                  onClick={() => closeModal(false)}
                >
                  cancel
                </button>
                <button
                  className="btn form-action-button form-save-button"
                  onClick={() => handleSendEmail()}
                  disabled={loading}
                >
                  Save
                </button>
                <img
                  style={{ display: !loading ? "none" : "block", top: '9px' }}
                  className="loader-icon"
                  src={loaderIcon}
                />
              </div>
            </div>
          </div>
          <div
            className="quix-dashboard-close"
            onClick={() => closeModal(false)}
          >
            <img src={closeIcon} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareByEmail;
