import React, { useRef, useEffect, useState } from "react";
import uninsatllEmoji from "../../assets/images/home/uninstallemoji.png";
import logoImg from "../../assets/images/home/logo.png";
import { Link } from "react-router-dom";
// import AdSenseAd from "../../components/Adsense/adSenseAd";
import FeedBackForm from "./components/FeedBackForm";

const Unintall = () => {
  // get dynamically heigth and width for adsense
  // const [adSenseEle, setAdSenseEle] = useState({ height: '100px', width: '500px' });
  
  // const adSenseRef = useRef();
  // useEffect(() => {
  //   if (adSenseRef.current) {
  //     const { clientWidth, clientHeight } = adSenseRef.current;
  //     console.log(clientWidth, clientHeight)

  //     setAdSenseEle({
  //       height: `${clientHeight}px`,
  //       width: `${clientWidth}px`
  //     })
  //   }
  // }, [adSenseRef.current]);

  return (
    <div className="uninstall-page" id="root">
      <div className="header_section">
        <div className="container top_container">
          <header className="ScreenGenius-header">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                  <img
                    className="sitetitle"
                    src={logoImg}
                    height="46"
                    alt="CoolBrand"
                  />
                </Link>
              </div>
            </nav>
          </header>
          <div className="adsense-outer container-fluid">
            {/* <div ref={adSenseRef} className="adsense-bar adsense-left-bar">
              <AdSenseAd
                client="ca-pub-4424794709901498"
                slot="7560981818"
                format="vertical"
                style={{ display: 'block', minWidth: "300px", width: adSenseEle.width, height: adSenseEle.height }}
                responsive={true}
              />
            </div>
            <div className="adsense-bar adsense-right-bar">
              <AdSenseAd
                client="ca-pub-4424794709901498"
                slot="7560981818"
                format="vertical"
                style={{ display: 'block', minWidth: "300px", width: adSenseEle.width, height: adSenseEle.height }}
                responsive={true}
              />
            </div> */}
            <div className="row">
              <div className="star_emoji">
                <span className="thankEmoji">
                  <span className="emoji">
                    <img src={uninsatllEmoji} />
                    <span className="emoji_content"></span>
                    <h5>
                      Vizam successfully uninstalled from your device now!
                    </h5>
                    <p>
                      Your experience matters to us. Would you mind taking few
                      minutes to share your feedback on our product?
                    </p>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feedback-section screen_recording_game">
        <div className="row m-0">
          <FeedBackForm />
        </div>
      </div>
    </div>
  );
};

export default Unintall;
