import React, { useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getUserDetailsByID } from '../../redux/actions/authentication';
import { localStoragePrefix } from '../../utils/config';

const AdSenseAd = ({ client, slot, style, format, responsive, userDetails }) => {
  const adRef = useRef(null);
  const dispatch = useDispatch();
  const userDetailsObj = userDetails?.data && userDetails?.data;
  const userPricingPlan = userDetailsObj?.pricing_plan && userDetailsObj?.pricing_plan;
  const planFeatures = userPricingPlan?.features && JSON.parse(userPricingPlan?.features);
  const adSense = planFeatures?.AdSense && planFeatures?.AdSense;
  const isAvaibleAdSense = adSense?.status ?? true;
  const tokenAuth = localStorage.getItem(
    localStoragePrefix + "screenGeniusAuthToken"
  );

  useEffect(() => {
    dispatch(getUserDetailsByID(tokenAuth, () => { }));
  }, []);

  const loadAdSenseScript = () => {
    if (!isAvaibleAdSense) return;
    const adsenseScript = document.createElement('script');
    adsenseScript.async = true;
    adsenseScript.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${client}`;
    adsenseScript.crossOrigin = "anonymous";
    document.body.appendChild(adsenseScript);

    const ampAdScript = document.createElement('script');
    ampAdScript.async = true;
    ampAdScript.setAttribute('custom-element', 'amp-ad');
    ampAdScript.src = "https://cdn.ampproject.org/v0/amp-ad-0.1.js";
    document.body.appendChild(ampAdScript);

    adsenseScript.onload = () => {
      if (adRef.current && adRef.current.children.length === 0) {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    };

    return () => {
      document.body.removeChild(adsenseScript);
      document.body.removeChild(ampAdScript);
    };
  }

  useEffect(() => {
    loadAdSenseScript();
  }, [isAvaibleAdSense]);

  if (!isAvaibleAdSense) return null;

  return (
    <div className='google-adsense'>
      <div>
        <ins
          ref={adRef}
          className="adsbygoogle"
          style={style}
          data-ad-client={client}
          data-ad-slot={slot}
          data-ad-format={format}
          data-full-width-responsive={responsive}
        ></ins>
      </div>
      <amp-ad
        width="100vw"
        height="320"
        type="adsense"
        data-ad-client={client}
        data-ad-slot={slot}
        data-auto-format="rspv"
        data-full-width="true"
      >
        <div overflow=""></div>
      </amp-ad>
    </div>
  );
};

AdSenseAd.defaultProps = {
  client: "",
  slot: "",
  style: {},
  format: "",
  responsive: true,
}

const mapStateToProps = (state) => ({
  userDetails: state?.auth?.user_details,
});

export default connect(mapStateToProps, {})(AdSenseAd);