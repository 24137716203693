import React, { useEffect, useState } from "react";
import { checkEmpty } from "../../helper/helper";
import closeIcon from "../../assets/images/quix-close.png";

const ShareDetailsForm = ({ data, closeModal, showModal, callback }) => {
    const itemTitle = data?.title && data?.title;
    const itemDescription = data?.summary && data?.summary;

    const intialData = {
        title: checkEmpty(itemTitle),
        description: checkEmpty(itemDescription),
    };
    const [formErrors, setFormErrors] = useState();
    const [formData, setFormData] = useState(intialData);

    useEffect(() => {
        setFormData(intialData);
    }, [data]);

    const ShareDetailsValidaionSchema = ({ formData, setFormErrors }) => {
        const { title } = formData;
        let valid = true;

        const errors = {
            titleError: "",
        };

        if (!title.trim("")) {
            errors.titleError = "Title cannot be empty!";
            valid = false;
        }
        setFormErrors(errors);
        return valid;
    };

    const handleSubmit = () => {
        if (ShareDetailsValidaionSchema({ formData: formData, setFormErrors })) {
            callback(formData);
        }
    };

    return (
        <>
            <div
                id="quix-dashboard-overlay"
                style={{
                    display: !showModal ? "none" : "block",
                }}
            ></div>
            <div id="quix-dashboard-popup">
                <div className="quix-dashboard-popup-inner notion-details-form">
                    <div className="quix-dashboard-popup-body">
                        <div className="quix-dashboard-popup-body-inner select-slack-channel-form card border-0">
                            <div className="card-body py-0">
                                <div className="title-notion-popup mb-2">
                                    <span>
                                        <b>Create a Title for Your Media</b>
                                    </span>
                                </div>
                                <div className="row">
                                    <div className="mb-3 quix-form-filed text-start">
                                        <label htmlFor="quix-title" className="mb-2">
                                            Title
                                        </label>
                                        <input
                                            type="text"
                                            name="title"
                                            className="form-control rounded-2"
                                            id="quix-title"
                                            aria-describedby="quix-title"
                                            value={formData?.title}
                                            onChange={(e) =>
                                                setFormData({ ...formData, title: e.target.value })
                                            }
                                            maxLength={255}
                                            style={{ width: "100%" }}
                                        />
                                        {formErrors?.titleError && (
                                            <p className="text-danger account-dec m-0">
                                                {formErrors?.titleError}
                                            </p>
                                        )}
                                    </div>
                                    <div className="mb-3 quix-form-filed text-start">
                                        <label htmlFor="quix-desc" className="mb-2">
                                            Description
                                        </label>
                                        <textarea
                                            type="text"
                                            name="description"
                                            className="form-control rounded-2"
                                            id="quix-desc"
                                            aria-describedby="quix-desc"
                                            onChange={(e) =>
                                                setFormData({ ...formData, description: e.target.value })
                                            }
                                            value={formData?.description}
                                            maxLength={255}
                                            rows={3}
                                            style={{ width: "100%", maxHeight: "100px" }}
                                        />
                                    </div>
                                    <div className="quix-form-filed text-center">
                                        <button
                                            className="btn form-action-button form-cancel-button"
                                            onClick={() => closeModal(false)}
                                        >
                                            cancel
                                        </button>
                                        <button
                                            className="btn form-action-button form-save-button"
                                            onClick={() => handleSubmit()}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="quix-dashboard-close"
                        onClick={() => closeModal(false)}
                    >
                        <img src={closeIcon} />
                    </div>
                </div>
            </div>
        </>
    );
};

ShareDetailsForm.defaultProps = {
    data: "",
    closeModal: "",
    callback: function () { },
};

export default ShareDetailsForm;
