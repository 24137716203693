import React, { useEffect, useState } from "react";
import logoIcon from "../../assets/images/home/footer-logo.png";
import upgradeIcon from "../../assets/images/quix-upgrade.png";
import notificationsIcon from "../../assets/images/quix-dash-notifications.png";
import cameraIcon from "../../assets/images/quix-dash-cam.png";
import videoIcon from "../../assets/images/quix-dash-vid.png";
import dummyUserIcon from "../../assets/images/dumy-user.jpg";
import { connect, useDispatch } from "react-redux";
import {
  logOutUser,
  manageUserProgress,
} from "../../redux/actions/authentication";
import {
  checkServerURL,
  failureMessagePopup,
  sq_profile_progress,
} from "../../helper/helper";
import { Link, useNavigate } from "react-router-dom";
import ProfileModal from "../Modals/Profile/ProfileModal";
import PricingModal from "../Modals/PricingPlan/PricingModal";
import {
  API_URL,
  localStoragePrefix,
} from "../../utils/config";
import WaterMarkUpload from "../Modals/WaterMarkUpload";
import LoadingPage from "../../views/loading-page/LoadingPage";
import UpgradeModal from "../Modals/UpgradeModal";

const Navbar = ({ activePage, userDetails, userProgress }) => {
  const userID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );
  const tokenAuth = localStorage.getItem(
    localStoragePrefix + "screenGeniusAuthToken"
  );
  const userRoleAuth = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserRole"
  );

  const detailsUserData = userDetails?.data && userDetails?.data;
  const userRole = detailsUserData?.role && Number(detailsUserData?.role);
  const userPricingPlan = detailsUserData?.pricing_plan && detailsUserData?.pricing_plan;
  const userFeatures = userPricingPlan?.features && JSON.parse(userPricingPlan?.features);
  const limitOfScreenShot = Number(userFeatures?.Unlimited?.screenshots);
  const limitOfVideos = Number(userFeatures?.Unlimited?.videos);


  const userProfile =
    !detailsUserData?.picture ||
      detailsUserData?.picture === "null" ||
      detailsUserData?.picture === null
      ? dummyUserIcon
      : checkServerURL(API_URL, `${detailsUserData?.picture}`);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigator = useNavigate();

  useEffect(() => {
    if (tokenAuth && userID) {
      dispatch(
        manageUserProgress(userID, tokenAuth, (type, res) => {
          if (type === "failed") {
            localStorage.removeItem(localStoragePrefix + "screenGeniusAuthToken");
            localStorage.removeItem(localStoragePrefix + "screenGeniusUserID");
            localStorage.clear();
            navigator("/login");
          }
        })
      );
    } else {
      navigator("/login");
    }
  }, [dispatch]);

  const initialValue = {
    name: detailsUserData?.name,
    email: detailsUserData?.email,
    picture: detailsUserData?.picture,
  };

  const [userdataDetails, setUserdataDetails] = useState(initialValue);
  const [progressStatus, setProgressStatus] = useState({
    screenshot_progress: 0,
    videos_progress: 0,
  });
  useEffect(() => {
    let { screenshots, videos } = userProgress;
    setUserdataDetails({
      name: initialValue?.name,
      email: initialValue?.email,
      picture: initialValue?.picture,
    });
    let userProgressDetails =
      screenshots || videos
        ? sq_profile_progress(screenshots, videos, limitOfScreenShot, limitOfVideos)
        : progressStatus;
    setProgressStatus(userProgressDetails);
  }, [userProgress, userDetails]);

  const handleLogOutUser = () => {
    const payload = {
      userID: userID,
      accessToken: tokenAuth,
    };
    setLoading(true);
    dispatch(
      logOutUser(payload, (res, type) => {
        if (type === "success") {
          localStorage.removeItem(localStoragePrefix + "screenGeniusAuthToken");
          localStorage.removeItem(localStoragePrefix + "screenGeniusUserID");
          setTimeout(() => {
            setLoading(false);
            navigator("/login");
          }, 600);
        } else if (type === "fail") {
          setLoading(false);
          failureMessagePopup("Error message", "Something went wrong!");
        }
      })
    );
  };
  // view profile modal
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [showPricingPlanPopup, setShowPricingPlanPopup] = useState(false);
  useEffect(() => {
    const checkAnchor = () => {
      const anchor = window.location.hash;
      if (anchor === "#pricing-plans") {
        setShowPricingPlanPopup(true);
      }
    };
    checkAnchor();
    window.addEventListener("hashchange", checkAnchor);
    return () => {
      window.removeEventListener("hashchange", checkAnchor);
    };
  }, []);
  // view water mark modal
  const [showSettings, setShowSettings] = useState(false);
  const [showUpgradeModal, setUpgradeModal] = useState(false);

  return (
    <div id="quix-dashboard-header">
      {loading && <LoadingPage />}
      <div className="quix-dashboard-left">
        <div className="quix-dashboard-logo">
          <Link to="/">
            <img src={logoIcon} />
          </Link>
        </div>
      </div>
      <div className="quix-dashboard-middle">
        <div id="quix-dashboard-sidebar">
          {/* <div
            className={`quix-dashboard-sidebar-items ${activePage === null ? "active" : ""
              }`}
          >
            <Link to="/">
              <div className="quix-dashboard-sidebar-item">
                <span>Home</span>
              </div>
            </Link>
          </div> */}
          <div
            className={`quix-dashboard-sidebar-items ${activePage === "dashboard" ? "active" : ""
              }`}
          >
            <Link to="/dashboard">
              <div className="quix-dashboard-sidebar-item">
                <span>My Items</span>
              </div>
            </Link>
          </div>
          <div
            className={`quix-dashboard-sidebar-items ${activePage === "starred" ? "active" : ""
              }`}
          >
            <Link to="/starred">
              <div className="quix-dashboard-sidebar-item">
                <span>Starred Items</span>
              </div>
            </Link>
          </div>
          {userRole === 1 && Number(userRoleAuth) === 1 && (
            <div
              className={`quix-dashboard-sidebar-items ${activePage === "admin" ? "active" : ""
                }`}
            >
              <Link to="/admin/dashboard">
                <div className="quix-dashboard-sidebar-item">
                  <span>Admin Dashboard</span>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="quix-dashboard-right">
        <div className="quix-dashboard-new-item">
          <div className="quix-dashboard-notification">
            <img title="Coming Soon" src={notificationsIcon} />
          </div>
          <div className="quix-dashboard-user">
            <img src={userProfile} />
            <div className="quix-dashboard-user-profile">
              <div className="quix-dashboard-user-profile-pic">
                <div className="user-profile-pic-outer">
                  <img
                    onClick={() => setShowProfilePopup(!showProfilePopup)}
                    src={userProfile}
                  />
                </div>
                <div className="user-profile-name-outer">
                  <span
                    className="user-profile-name"
                    onClick={() => setShowProfilePopup(!showProfilePopup)}
                    title={userdataDetails.name}
                  >
                    {userdataDetails.name}
                  </span>
                  <span
                    className="user-profile-email"
                    title={userdataDetails.email}
                  >
                    {userdataDetails.email}
                  </span>
                </div>
              </div>
              {detailsUserData?.pricing_plan?.id &&
                detailsUserData?.pricing_plan?.id === 3 && (
                  <div className="quix-dashboard-user-profile-upgrade">
                    <div
                      // onClick={() =>
                      //   setShowPricingPlanPopup(!showPricingPlanPopup)
                      // }
                      onClick={()=>setUpgradeModal(!showUpgradeModal)}
                      className="quix-dashboard-upgrade-button dashboard-button"
                      title="Coming Soon"
                    >
                      <img src={upgradeIcon} />
                      <span className="user-profile-upgrade">Upgrade</span>
                    </div>
                  </div>
                )}
              <div className="quix-dashboard-divider">&nbsp;</div>
              <div className="quix-dashboard-user-profile-progress">
                <div className="quix-dashboard-user-profile-progress-images">
                  <img src={cameraIcon} />
                  <span>Images</span>
                  <span className="images-progress">
                    <span
                      className="images-progress-inner"
                      style={{
                        width: `${progressStatus?.screenshot_progress}%`,
                      }}
                      title={`${userProgress?.screenshots}/${limitOfScreenShot === -1 ? 'Unlimited' : limitOfScreenShot}`}
                    >
                      &nbsp;
                    </span>
                  </span>
                </div>
                <div className="quix-dashboard-user-profile-progress-images">
                  <img src={videoIcon} />
                  <span>Videos</span>
                  <span className="videos-progress">
                    <span
                      className="videos-progress-inner"
                      style={{ width: `${progressStatus?.videos_progress}%` }}
                      title={`${userProgress?.videos}/${limitOfVideos === -1 ? 'Unlimited' : limitOfVideos}`}
                    >
                      &nbsp;
                    </span>
                  </span>
                </div>
              </div>
              <div className="quix-dashboard-divider">&nbsp;</div>
              <div className="quix-dashboard-user-profile-links">
                <div className="quix-dashboard-user-profile-links-row help-row">
                  <span>
                    <Link to="#" title="Coming Soon">
                      Help
                    </Link>
                  </span>
                </div>
                {/* <div className="quix-dashboard-user-profile-links-row">
                  <span>
                    <Link to="/profile">Profile</Link>
                  </span>
                </div> */}
                <div className="quix-dashboard-user-profile-links-row profile-row">
                  <span onClick={() => setShowProfilePopup(!showProfilePopup)}>
                    Profile
                  </span>
                </div>
                <div className="quix-dashboard-user-profile-links-row pricing-row">
                  <span
                    // onClick={() =>
                    //   setShowPricingPlanPopup(!showPricingPlanPopup)
                    // }
                    title="Coming Soon"
                  >
                    Pricing Plans
                  </span>
                </div>
                {/* <div className="quix-dashboard-user-profile-links-row setting-row">
                  <span title="Coming Soon">Settings</span>
                </div> */}
                {userFeatures?.Watermark?.status ? (
                  <div className="quix-dashboard-user-profile-links-row">
                    <span onClick={() => setShowSettings(true)}>
                      Watermark Settings
                    </span>
                  </div>
                ) : (
                  <div className="quix-dashboard-user-profile-links-row setting-row">
                    <span className="user-paid-feature-outer" onClick={() => setUpgradeModal(!showUpgradeModal)}>
                      Watermark Settings
                      <span className='user-paid-feature'>🔒</span>
                    </span>
                  </div>
                )}
                <div
                  className="quix-dashboard-user-profile-links-row logout-row"
                  onClick={() => handleLogOutUser()}
                >
                  <span className="button-logout">Logout</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showUpgradeModal && (
        <UpgradeModal
          showModal={showUpgradeModal}
          closeModal={setUpgradeModal}
        />
      )}
      {showSettings && (
        <WaterMarkUpload
          showModal={showSettings}
          closeModal={setShowSettings}
          cardData={detailsUserData}
        />
      )}
      {showProfilePopup && (
        <ProfileModal
          showModal={showProfilePopup}
          closeModal={setShowProfilePopup}
        />
      )}
      {showPricingPlanPopup && (
        <PricingModal
          showModal={showPricingPlanPopup}
          closeModal={setShowPricingPlanPopup}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state?.auth?.user_details,
  userProgress: state?.auth?.progress,
});

export default connect(mapStateToProps, {
  manageUserProgress,
})(Navbar);
